import { post } from '@/http'
// 新建发票
export function getOrderDetail(data) {
    return new Promise((resolve, reject) => {
        post('/Order/GetOrderDetail', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 评价标签
export function getProductEvTip(data) {
    return new Promise((resolve, reject) => {
        post('/Evaluate/GetProductEvTip', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 提交评价
export function submitEva(data) {
    return new Promise((resolve, reject) => {
        post('/Evaluate/SubmitEva', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 提交评价
export function getEvaState(data) {
    return new Promise((resolve, reject) => {
        post('/Evaluate/GetEvaState', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
