<template>
	<div>
		<div class="top">
			<div class="top-left">
				<div class="state">
					<div class="state-label">订单状态：</div>
					<div class="state-text">
						<template v-if="!order.EX">
							<span v-if="order.State == 0">待支付</span>
							<span v-if="order.State == 1">待发货</span>
							<span v-if="order.State == 2">待收货</span>
							<span v-if="order.State == 3">待评价</span>
							<span v-if="order.State == 4">已完成</span>
							<span v-if="order.State == 5">已取消</span>
							<span v-if="order.State == 6">交易关闭</span>
						</template>
						<template v-if="order.EX">
							<span>部分订单退款中</span>
						</template>
					</div>
				</div>
				<div v-if="order.State == 0" class="payment">
					您还有
					<span v-if="showtimestatus">{{ order.time }}</span>
					来付款, 超时订单将自动关闭
				</div>
				<div v-if="order.State == 0">
					点击这里
					<span class="handle">付款</span>
				</div>
				<div v-if="order.State == 3">
					点击这里
					<span class="handle" @click="toEvaluate()">评价</span>
				</div>
				<div v-if="order.State == 1"><div>商品正在准备出库，请耐心等待……</div></div>
				<div v-if="order.State == 2">
					<template v-if="!order.EX">
						您还有
						<span v-if="showconfirmTimestatus">{{ order.confirmTime }}</span>
						来确认收货，超时将自动确认收货。
					</template>

					<div style="margin-top: 20px">
						点击这里
						<el-button size="mini" style="background: #0097BA; color: #FFFFFF; border: none" @click="confirm()">确认收货</el-button>
					</div>
				</div>
				<!--                <div>-->
				<!--                    <div>您当前订单中，部分商品申请了退款</div>-->
				<!--                    <div class="view">点击这里查看-->
				<!--                        <button>退款详情</button>-->
				<!--                    </div>-->
				<!--                </div>-->
			</div>
			<div class="top-right">
				<div class="top-right-text">
					<div>1.提交订单</div>
					<div>2.付款成功</div>
					<div>3.已发货</div>
					<div>4.确认收货</div>
					<div>5.评价</div>
				</div>
				<div class="top-right-icon">
					<div>
						<div :class="['icon-main border', { active: order.State >= 0 && order.State < 5 }]"><i class="iconfont icontijiao-"></i></div>
					</div>
					<div>
						<div :class="['icon-main border', { active: order.State >= 1 && order.State < 5 }]"><i class="iconfont iconyifukuan"></i></div>
					</div>
					<div>
						<div :class="['icon-main border', { active: order.State >= 2 && order.State < 5 }]"><i class="iconfont iconwuliu"></i></div>
					</div>
					<div>
						<div :class="['icon-main border', { active: order.State >= 3 && order.State < 5 }]"><i class="iconfont iconkuaidi"></i></div>
					</div>
					<div>
						<div :class="['icon-main ', { active: order.State >= 4 && order.State < 5 }]"><i class="iconfont iconchangyongicon-"></i></div>
					</div>
				</div>
				<div class="top-right-date">
					<div>
						<div v-if="order.State >= 0 && order.State < 5">
							<div>{{ order.CreatTime.substring(0, order.CreatTime.length - 8) }}</div>
							<div>{{ order.CreatTime.substring(10, order.CreatTime.length) }}</div>
						</div>
					</div>
					<div>
						<div v-if="order.State >= 1 && order.State < 5">
							<div>{{ order.PayTime.substring(0, order.PayTime.length - 8) }}</div>
							<div>{{ order.PayTime.substring(10, order.PayTime.length) }}</div>
						</div>
					</div>
					<div>
						<div v-if="order.State >= 2 && order.State < 5">
							<div>{{ order.ShipmentsTime.substring(0, order.ShipmentsTime.length - 8) }}</div>
							<div>{{ order.ShipmentsTime.substring(10, order.ShipmentsTime.length) }}</div>
						</div>
					</div>
					<div>
						<div v-if="order.State >= 3 && order.State < 5">
							<div>{{ order.ConsignTime.substring(0, order.ConsignTime.length - 8) }}</div>
							<div>{{ order.ConsignTime.substring(10, order.ConsignTime.length) }}</div>
						</div>
					</div>
					<div>
						<div v-if="order.State >= 4 && order.State < 5">
							<div>{{ order.OverTime.substring(0, order.OverTime.length - 8) }}</div>
							<div>{{ order.OverTime.substring(10, order.OverTime.length) }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="logistics" v-if="order.State >= 2 && order.State < 5 && order.TrackingNum">
			<div class="logistics-top">物流信息</div>
			<table border="1" cellspacing="0" cellpadding="0">
				<tr class="logistics-text">
					<td width="200">物流公司</td>
					<td width="825">{{ information.ComName }}</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">运单号码</td>
					<td width="825">
						{{ order.TrackingNum }}
						<!--                        <span>物流跟踪</span>-->
					</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">物流跟踪</td>
					<td width="825">
						<div style="font-size:12px" v-for="(item, index) in information.TrackList" :key="index">
							<div>
								<b style="color:rgb(27, 152, 170)">{{ item.ftime }}</b>
								{{ item.context }}
							</div>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<div class="list">
			<div>
				<table border="0" cellspacing="0" cellpadding="0">
					<tr class="list-title">
						<th width="460">商品信息</th>
						<th width="268">单价(元)</th>
						<th width="200">数量</th>
						<th width="200">操作</th>
						<th width="200">实付款(元)</th>
					</tr>
				</table>

				<!--订单号-->
				<table style="border-collapse:separate; border-spacing:0 10px;" border="0" cellspacing="0" cellpadding="0">
					<tr class="order-mian" style="margin:10px">
						<td width="450" style="padding-left:10px">
							订单号 {{ order.OrderID }} |
							<span class="order-number">{{ order.CreatTime }}</span>
						</td>
						<td width="268"></td>
						<td width="200" v-for="j in 3" :key="j"></td>
					</tr>
					<tr class="order-mian-list" v-for="(j, i) in order.DetailList" :key="i">
						<td style="display: flex" class="border">
							<div><img :src="pic + j.ProPictrue" alt="" /></div>
							<div class="order-mian-list-text">
								<div>{{ j.ProName }}</div>
								<div>{{ j.BarCode }}</div>
							</div>
						</td>
						<td class="border">
							<div>￥{{ j.ProUnitPrice }}</div>
						</td>
						<td class="border">
							<div>X {{ j.PCount }}</div>
						</td>
						<td class="border">
							<div class="refund">
								<el-button size="mini" v-if="order.State == 4 && order.show && j.ExState == 0" @click="refund(order, j)">申请售后</el-button>
								<el-button size="mini" v-if="(order.State == 1 || order.State == 2 || order.State == 3) && j.ExState == 0" @click="refund(order, j)">申请售后</el-button>

								<span style="color: #DC2310" v-if="j.ExState == 6" @click="view(j)">退款中</span>
								<span style="color: #DC2310" v-if="j.ExState == 7" @click="view(j)">退款完成</span>
								<span style="color: #DC2310" v-if="j.ExState == 8" @click="view(j)">退款失败</span>
								<!-- <span
																    style="color: #DC2310"
																    v-if="j.ExState == 9"
																    @click="view(j)"
																    >部分已退款</span
																> -->

								<div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: center;" v-if="j.ExState == 9">
									<div style="color: #DC2310;cursor: pointer;" @click="view(j)">部分已退款</div>
									<div style="cursor: pointer;" class="button" @click="refund(item, j)"><span>申请售后</span></div>
								</div>
							</div>
						</td>
						<td class=" border-left border-right" :rowspan="order.DetailList.length" v-if="i == 0 && order.DetailList">
							<div class="subtotal">
								<div>￥ {{ order.p1 }}</div>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="logistics">
			<div class="logistics-top">收货信息</div>
			<table border="1" cellspacing="0" cellpadding="0">
				<tr class="logistics-text">
					<td width="200">收货地址</td>
					<td width="825">
						<div>收货人：{{ order.UserName }} {{ order.UserPhone }}</div>
						<div>{{ order.Province }}{{ order.City }}{{ order.District }} {{ order.Address }}</div>
					</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">固定电话</td>
					<td width="825">{{ order.UserPhone }}</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">邮政编码</td>
					<td width="825">无 {{ order.Postcode }}</td>
				</tr>
			</table>

			<div
				class="cart"
				@click="toChat"
				style="background-color:#0097ba ;width: 180px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				float: right;
				margin-top: 20px;color:#fff;
				cursor: pointer;"
			>
				联系客服
			</div>
		</div>
		<div class="logistics" v-if="order.InvoiceNumber && order.InvoiceNumber > 0">
			<div class="logistics-top">发票信息</div>
			<table border="1" cellspacing="0" cellpadding="0">
				<tr class="logistics-text">
					<td width="200">发票状态</td>
					<td width="825" style="color: #0097BA"><span>已开票</span></td>
				</tr>
				<tr class="logistics-text">
					<td width="200">发票内容</td>
					<td width="825">商品明细</td>
				</tr>

				<tr class="logistics-text">
					<td width="200">发票类型</td>
					<td width="825">
						<div v-if="order.InvoiceType == 2 || order.InvoiceType == 3" class="payment-list-button">
							增值税普通发票
							<span></span>
						</div>
						<div v-if="order.InvoiceType == 1" class="payment-list-button">增值税专用发票</div>
						<div v-if="order.InvoiceType == 3" class="payment-list-info">个人</div>
						<div v-if="order.InvoiceType == 2 || order.InvoiceType == 1" class="payment-list-info">单位</div>
					</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">发票抬头</td>
					<td width="825">{{ order.InvoiceTitle }}</td>
				</tr>
				<tr class="logistics-text" v-if="order.InvoiceType == 1 || order.InvoiceType == 2">
					<td width="200">公司税号</td>
					<td width="825">{{ order.DutyParagraph }}</td>
				</tr>
			</table>
		</div>
		<div class="total" v-if="order.PayType > 0">
			<div class="total-left">
				<div class="total-left-list">
					<span>支付方式：</span>
					<span>手机支付</span>
				</div>
				<div class="total-left-list">
					<span>支付平台：</span>
					<span v-if="order.PayType == 0">未支付</span>
					<span v-if="order.PayType == 1">微信</span>
					<span v-if="order.PayType == 2">支付宝</span>
					<span v-if="order.PayType == 3">对公付款</span>
				</div>
				<div class="total-left-list">
					<span>支付平台流水单号：</span>
					<span>{{ order.PayNumber }}</span>
				</div>
				<div class="total-left-list">
					<span>支付时间：</span>
					<span>{{ order.PayTime }}</span>
				</div>
			</div>
			<div class="total-right" v-if="order.DetailList">
				<div class="total-right-item">
					<div class="total-right-item-lable">
						<span class="number">{{ order.DetailList.length }}</span>
						件商品，总商品金额：
					</div>
					<div class="ct">￥{{ order.p4 }}</div>
				</div>
				<div class="total-right-item">
					<div class="total-right-item-lable">(含) 税费：</div>
					<div class="ct">￥{{ order.p2 }}</div>
				</div>
				<div class="total-right-item">
					<div class="total-right-item-lable">运费：</div>
					<div class="number">免运费</div>
				</div>
				<div class="total-right-item">
					<div class="total-right-item-lable">优惠：</div>
					<div class="number">￥{{ order.p3 }}</div>
				</div>
				<div class="total-right-item">
					<div class="total-right-item-lable">实付款：</div>
					<div class="pay">￥{{ order.p1 }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getOrderDetail } from '../toEvaluate/service';
import { agreenOrder, queryInformation } from '../payment/service';
import { applyEx } from './service';

export default {
	data() {
		return {
			showtimestatus: true,
			showconfirmTimestatus: true,
			pic: FILE_URL,
			lastPrice: 0,
			information: [],
			order: {},
			TaxPrice: '',
			cartList: []
		};
	},
	mounted() {
		this.OrderID = this.$route.query.OrderID;
		this.init();
		setInterval(() => {}, 1000);
	},
	methods: {
		toChat() {
			window.open('https://mcs.hexagonmetrology.cn/im/text/0hnzjp.html')
			// let proName = [];
			// this.order.DetailList.forEach(v => {
			// 	proName.push(v.ProName);
			// });
			// this.ysf('product', {
			// 	show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
			// 	title: '订单号：' + this.order.OrderID,
			// 	desc: '订单商品：' + proName.join('/'),
			// 	picture: FILE_URL + this.order.DetailList[0].ProPictrue,
			// 	note: '实付金额：￥' + this.order.TotalPrice,
			// 	success: () => {
			// 		// 成功回调
			// 		this.ysf('open');
			// 	},
			// 	error: () => {
			// 		// 错误回调
			// 		this.ysf('open');
			// 	}
			// });
		},

		// 去评价
		toEvaluate() {
			this.$router.push({
				path: `/me/toEvaluate?OrderID=${this.order.OrderID}`
			});
		},
		// 退款
		refund(item, j) {
			applyEx({ OrderID: item.OrderID }).then(res => {
				if (res.code == 1) {
					if (item.State == 1) {
						this.$router.push({
							path: `/me/fillIn?CreatTime=${j.CreatTime}&OrderID=${j.OrderID}&ProName=${j.ProName}&ProUnitPrice=${j.ProUnitPrice}&PCount=${j.PCount}&DisPrice=${
								j.DisPrice
							}&LastPrice=${j.LastPrice}&ProPictrue=${j.ProPictrue}&BarCode=${j.BarCode}&TaxPrice=${j.TaxPrice}&PID=${j.PID}&type=1`
						});
					} else {
						this.$router.push({
							path: `/typeGoods?CreatTime=${j.CreatTime}&OrderID=${j.OrderID}&ProName=${j.ProName}&ProUnitPrice=${j.ProUnitPrice}&PCount=${j.PCount}&DisPrice=${
								j.DisPrice
							}&LastPrice=${j.LastPrice}&ProPictrue=${j.ProPictrue}&BarCode=${j.BarCode}&TaxPrice=${j.TaxPrice}&PID=${j.PID}`
						});
					}
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		init() {
			getOrderDetail({
				OrderID: this.OrderID
			}).then(res => {
				let p1 = 0;
				let p2 = 0;
				let p3 = 0;
				let p4 = 0;
				for (let j in res.data.DetailList) {
					p1 += res.data.DetailList[j].LastPrice;
					p2 += res.data.DetailList[j].TaxPrice * res.data.DetailList[j].PCount;
					p3 += res.data.DetailList[j].DisPrice;
					p4 += res.data.DetailList[j].PriceTotal;
				}
				p1 = p1.toFixed(2);
				p2 = p2.toFixed(2);
				p3 = p3.toFixed(2);
				p4 = p4.toFixed(2);
				res.data.p1 = p1;
				res.data.p2 = p2;
				res.data.p3 = p3;
				res.data.p4 = p4;
				if (res.data.State == 0) {
					setInterval(() => {
						this.showtimestatus = false;
						let dateTime = new Date(res.data.CreatTime);
						dateTime = dateTime.setDate(dateTime.getDate() + 1);
						dateTime = new Date(dateTime);
						res.data.time = this.showtime(dateTime);
						this.showtimestatus = true;
					}, 1000);
				}
				if (res.data.State == 2) {
					setInterval(() => {
						this.showconfirmTimestatus = false;
						let dateTime = new Date(res.data.ShipmentsTime);
						dateTime = dateTime.setDate(dateTime.getDate() + 12);
						dateTime = new Date(dateTime);
						res.data.confirmTime = this.showtime(dateTime);
						this.showconfirmTimestatus = true;
					}, 1000);
				}

				for (let j in res.data.DetailList) {
					if (res.data.DetailList[j].ExState == 6) {
						res.data.EX = true;
					}
				}

				this.order = res.data;
				var dateTime = new Date(this.order.OverTime);
				var dateTime1 = new Date();
				dateTime = dateTime.setDate(dateTime.getDate() + 14);
				dateTime = new Date(dateTime);

				if (dateTime < dateTime1) {
					this.order.show = false;
				} else {
					this.order.show = true;
				}

				this.showLogistics();
			});
		},
		// 确认收货
		confirm() {
			this.$confirm('你确定要执行吗, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				agreenOrder({
					OrderID: this.order.OrderID
				}).then(() => {
					this.$message.success('收货完成');
					this.$router.go(-1);
					this.init();
				});
			});
		},
		showLogistics() {
			if (!this.order.TrackingNum) {
				return;
			}
			queryInformation({
				OrderID: this.order.OrderID
			}).then(res => {
				this.information = res.data;
			});
		},
		view(j) {
			this.$router.push({
				path: `/me/goodsDetails?OrderID=${j.ExOrderID}`
			});
		},
		showtime(data) {
			let nowtime = new Date(), //获取当前时间
				endtime = new Date(data); //定义结束时间
			let lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
				days = parseInt(lefttime / 1000 / 60 / 60 / 24), //计算剩余的天数
				lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
				leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
				lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
			if (lefts.length == 1) {
				lefts = '0' + lefts;
			}
			lefth = lefth >= 0 && lefth < 10 ? '0' + lefth : '' + lefth;
			leftm = leftm >= 0 && leftm < 10 ? '0' + leftm : '' + leftm;
			lefts = lefts >= 0 && lefts < 10 ? '0' + lefts : '' + lefts;
			this.time = lefth + '小时' + leftm + '分' + lefts + '秒';
			if (lefth.startsWith('-')) {
				return '00小时00分00秒';
			} else {
				return days + '天' + lefth + '小时' + leftm + '分' + lefts + '秒';
			}
		}
	}
};
</script>

<style lang="less" scoped>
.payment {
	span {
		color: #e35d51;
	}
}

.state-label {
	margin-top: 5px;
}

.handle {
	margin: 10px auto;
	padding: 5px 15px;
	color: #ffffff;
	background: #dc2310;
	cursor: pointer;
	color: #ffffff;
}

.total {
	background: #f5f5f5;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	padding: 40px 30px;
	box-sizing: border-box;

	.total-right-item {
		display: flex;
		margin: 20px 0;

		.total-right-item-lable {
			width: 250px;
			text-align: right;
			padding-right: 20px;
			color: #666666;
			margin-right: 60px;
		}

		.pay {
			font-family: 'ct';
			color: #dc2310;
			font-size: 22px;
		}
	}

	.total-left-list {
		margin: 30px 0;
		width: 500px;
		color: #666666;
	}

	.total-right-label {
		background: red;
		width: 300px;
	}

	.total-right {
		.number {
			font-family: 'ct';
			color: #dc2310;
			margin: 0 10px;
		}
	}

	.ct {
		font-family: 'ct';
	}

	.total-left-list-addr {
		display: flex;
	}

	.button {
		padding: 2px 10px;
		color: #666666;
		border: 1px solid #bfbfbf;
		margin-left: 20px;
	}

	.info {
		margin-left: 10px;
	}

	.addrs {
		width: 500px;
		margin-left: 20px;
	}
}

.refund {
	cursor: pointer;
}

.logistics {
	text-align: left;
	margin: 10px 0;

	.logistics-top {
		line-height: 40px;
		height: 40px;
		background: #f9f9f9;
		padding-left: 20px;
		box-sizing: border-box;
		font-family: 'ct';
	}

	table {
		border: 1px solid #f4f4f4;
		border-collapse: collapse;
		border-spacing: 0;
	}

	.logistics-text {
		font-family: '微软雅黑';
		font-size: 13px;
		color: #666666;

		span {
			color: #1b98aa;
			cursor: pointer;
			margin-left: 10px;
		}

		td {
			height: 40px;
			line-height: 40px;
			padding-left: 20px;
			border-top: none;
		}
	}
}

.list {
	margin-top: 10px;

	.list-title {
		background: #eeeeee;
		height: 40px;
		text-align: center;
	}

	.order-mian {
		width: 1300px;
		height: 30px;
		line-height: 30px;
		font-size: 12px;
		margin: 10px 0;
		background: #f9f9f9;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.order-mian-list {
		text-align: center;
		font-size: 12px;
		margin: 10px 0;
	}

	.order-mian-list-text {
		text-align: left;
		margin: 10px;
	}

	.subtotal {
		font-family: 'ct';
		color: #1b98aa;
		font-size: 17px;

		.subtotal-tax {
			font-family: '微软雅黑';
			font-size: 12px;
			color: #999999;
		}
	}

	.button {
		width: fit-content;
		padding: 2px 10px;
		margin: 10px auto;
		background: rgba(27, 152, 170, 0);
		border: 1px solid #d9d9d9;
		color: #999999;
	}

	img {
		width: 100px;
		height: 100px;
	}

	.border {
		padding-bottom: 10px;
		border-bottom: 1px solid #f4f4f4;
	}

	.border-left {
		border-left: 1px solid #f4f4f4;
	}

	.border-right {
		border-right: 1px solid #f4f4f4;
		border-bottom: 1px solid #f4f4f4;
	}

	.order-details {
		margin-top: 30px;
	}

	.paidui {
		color: #999999;

		span {
			margin-left: 5px;
		}
	}

	.handle {
		width: fit-content;
		margin: 10px auto;
		padding: 5px 15px;
		color: #ffffff;
		background: #dc2310;
	}
}

.top {
	display: flex;
	background: #fdfdfd;
	border: 1px solid #dcdcdc;
	padding: 20px 20px;
	width: 1009px;

	> div {
		width: 50%;
	}

	.top-left {
		border-right: 1px solid #dcdcdc;

		> div {
			margin-bottom: 20px;
		}

		.state-text {
			font-size: 18px;
			color: #dc2310;
			margin-left: 20px;
			font-family: 'ct';
		}

		.view {
			color: #666666;

			button {
				color: #ffffff;
				background: #0097ba;
				border: 1px solid #dcdcdc;
				padding: 5px 10px;
				outline: none;
				cursor: pointer;
			}
		}
	}

	.top-right-text {
		display: flex;
		justify-content: center;
		color: #333333;
		text-align: center;

		> div {
			width: 90px;
			margin: 0 10px;
		}
	}

	.top-right-icon {
		display: flex;
		justify-content: center;

		> div {
			width: 90px;
			margin: 0 10px;
		}

		.border {
			position: relative;

			&:after {
				position: absolute;
				content: '';
				width: 52px;
				height: 1px;
				background: #d9d9d9;
				top: 25px;
				left: 50px;
			}
		}

		.icon-main {
			width: 50px;
			height: 50px;
			background: #dcdcdc;

			text-align: center;
			line-height: 50px;
			border-radius: 50%;
			margin-left: 15px;
			margin-top: 15px;

			i {
				color: #ffffff;
			}
		}

		.active {
			background: #1b98aa;
			color: #999999;
		}

		i {
			font-size: 30px;
		}
	}

	.state {
		display: flex;
	}

	.top-right-date {
		display: flex;
		margin-top: 14px;
		justify-content: center;
		color: #999999;
		text-align: center;
		font-size: 12px;

		> div {
			width: 90px;
			margin: 0 10px;
		}
	}
}
</style>
